<template>
  <div class="row">
    <button class="btn btn-primary px-2 right-button" @click="addNewMember">
      Add a member
    </button>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <h5>
        You have assigned {{ activeUserCount }} of your {{ licenses }} licenses.
      </h5>
    </div>
  </div>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="members">
      <template #name="{ text }">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          <a class="btn btn-sm btn-light" href="javascript: void(0);">{{
            text
          }}</a>
        </template>
      </template>
      <template #action="{ text }">
        <span>
          <a
            href="javascript: void(0);"
            class="btn btn-sm btn-light mr-2"
            @click="openEditView(text.id, text.name)"
          >
            <i class="fe fe-edit mr-2" />
            Edit
          </a>
          <a
            href="javascript: void(0);"
            class="btn btn-sm mr-2"
            @click="
              text.role > 0
                ? removeMember(text.id, text.name, text.email, text.role)
                : ''
            "
            :class="text.role > 0 ? 'btn-danger' : 'btn-disabled'"
          >
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            Remove
          </a>
          <a
            href="javascript: void(0);"
            class="btn btn-sm btn-light mr-2"
            @click="resetPassword(text.name, text.email)"
          >
            <i class="fe fe-edit mr-2" />
            Reset Password
          </a>
        </span>
      </template>
      <template #status="{ text }">
        <span>
          <a-switch
            :checked="text.status === 2"
            @change="changeStatus(text.id, text.status)"
          />
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import AButton from "@/components/Button";

const columns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    slots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customer",
    },
    onFilter: (value, record) =>
      record.customer.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Action",
    slots: { customRender: "action" },
  },
  { title: "Active", slots: { customRender: "status" } },
];

export default {
  components: {
    AButton,
  },
  props: ["currentOrg", "currentUsers", "userStatus"],
  emits: [
    "openEditView",
    "addNewMember",
    "removeMember",
    "resetPassword",
    "changeStatus",
  ],
  setup(props) {
    const searchText = ref("");
    const searchInput = ref(null);
    const members = reactive([]);
    const licenses = props.currentOrg.licenses;
    const activeUserCount = ref(0);
    const userStatus = props.userStatus;

    Object.assign(members, props.currentUsers);
    activeUserCount.value = props.currentUsers.filter(
      (user) => user.status > 1
    ).length;

    watch(props.currentUsers, () => {
      members.splice(0);
      Object.assign(members, props.currentUsers);
      activeUserCount.value = props.currentUsers.filter(
        (user) => user.status > 1
      ).length;
    });

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      searchText.value = selectedKeys[0];
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      searchText.value = "";
    };

    return {
      searchText,
      searchInput,
      columns,
      handleReset,
      handleSearch,
      members,
      licenses,
      activeUserCount,
    };
  },
  methods: {
    openEditView(key, name) {
      const user = {
        id: key,
        name: name,
      };
      this.$emit("openEditView", user);
    },
    addNewMember() {
      this.$emit("addNewMember");
    },
    removeMember(id, name, email, role) {
      const that = this;
      if (role < 1) {
        this.$confirm({
          title: "Remove user",
          content: "You cannot remove the owner account from the organisation",
          onOk() {},
        });
      } else {
        this.$confirm({
          title: "Remove user",
          content:
            "Do you want to remove " +
            name +
            " (" +
            email +
            ") " +
            "from your organisation?",
          onOk() {
            that.$emit("removeMember", id);
          },
          onCancel() {},
        });
      }
    },
    resetPassword(name, email) {
      const that = this;
      this.$confirm({
        title: "Send Password Reset Email?",
        content:
          "Do you want to send a password reset email to this team member (" +
          name +
          " - " +
          email +
          ")?",
        onOk() {
          that.$emit("resetPassword", email);
        },
        onCancel() {},
      });
    },
    changeStatus(id, status) {
      if (this.userStatus > 1) {
        const user = {
          id: id,
          status: status === 2 ? 1 : 2,
        };
        if (status === 1) {
          if (this.activeUserCount < this.licenses) {
            this.members.find((member) => member.id === id).status = 2;
            this.$emit("changeStatus", user);
          } else {
            this.members.find((member) => member.id === id).status = 1;
            this.$confirm({
              title: "Oops! Maximum Licenses Reached",
              content:
                "You have reached the maximum number of licenses. Please upgrade or deactivate other members to activate this member!",
              onOk() {},
              onCancel() {},
            });
          }
        } else {
          this.members.find((member) => member.id === id).status = 1;
          this.$emit("changeStatus", user);
        }
      } else {
        this.$confirm({
          title: "Oops! Permission Required",
          content:
            "You do not have permission to edit team members. Please contact the owner of the orgisation for permission.",
          onOk() {},
          onCancel() {},
        });
      }
    },
    getStatus(email) {
      return this.members.find((member) => member.email === email).status == 2;
    },
  },
};
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.right-button {
  margin-top: auto;
  margin-bottom: 20px;
  margin-right: 15px;
}
</style>
