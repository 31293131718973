<template>
  <div v-if="currentOrg.role < 2 && userStatus > 1">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <team-table
              :currentUsers="currentUsers"
              :currentOrg="currentOrg"
              :userStatus="userStatus"
              @openEditView="openEditView"
              @addNewMember="addNewMember"
              @removeMember="removeMember"
              @resetPassword="resetPassword"
              @changeStatus="changeStatus"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <a-modal :visible="showNewMember" @cancel="cancel" @ok="submitNewMember">
        <a-form
          :model="newMemberFormState"
          :label-col="labelCol"
          :wrapperCol="wrapperCol"
        >
          <h5>New Member Details</h5>
          <a-form-item label="Email">
            <a-input
              v-model:value="newMemberFormState.email"
              placeholder="Email Address"
              required
              type="email"
            />
          </a-form-item>

          <a-form-item label="Full Name">
            <a-input
              v-model:value="newMemberFormState.name"
              placeholder="Full Name"
              required
            />
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal :visible="showEditView" @cancel="cancel" @ok="submitEdit">
        <a-form
          :model="editFormState"
          :label-col="labelCol"
          :wrapperCol="wrapperCol"
        >
          <h5>Edit Member Details</h5>
          <a-form-item label="Name">
            <a-input
              placeholder="Name"
              v-model:value="editFormState.name"
              required
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Sorry, it seems like you do not have access to this page.</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamTable from "@/components/Team/TeamTable";
import { useStore } from "vuex";
import { computed, reactive, ref, watch } from "@vue/runtime-core";

export default {
  name: "VbTeam",
  components: {
    TeamTable,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    const showNewMember = ref(false);
    const showEditView = ref(false);
    const currentOrg = reactive({});
    const currentUsers = reactive([]);
    const userStatus = ref(0);

    Object.assign(currentUsers, user.value.currentOrg.users);
    Object.assign(currentOrg, user.value.currentOrg);

    const match = user.value.currentOrg.users.find((usr) => {
      return usr.id === user.value.id;
    });

    if (match) {
      userStatus.value = match.status;
    }

    watch(user.value, () => {
      Object.assign(currentOrg, user.value.currentOrg);
      currentUsers.splice(0);
      Object.assign(currentUsers, user.value.currentOrg.users);
      const match = user.value.currentOrg.users.find((usr) => {
        return usr.id === user.value.id;
      });

      if (match) {
        userStatus.value = match.status;
      }
    });

    const newMemberFormState = reactive({
      email: "",
      name: "",
    });

    const editFormState = reactive({
      id: "",
      name: "",
    });

    const labelCol = { span: 4 };
    const wrapperCol = { span: 14 };
    return {
      newMemberFormState,
      editFormState,
      currentOrg,
      currentUsers,
      showNewMember,
      showEditView,
      store,
      userStatus,
      labelCol,
      wrapperCol,
    };
  },
  methods: {
    addNewMember() {
      this.showNewMember = true;
    },
    openEditView(user) {
      Object.assign(this.editFormState, user);
      this.showEditView = true;
    },
    submitEdit() {
      this.store
        .dispatch("user/EDIT_MEMBER", {
          orgId: this.currentOrg.id,
          uid: this.editFormState.id,
          name: this.editFormState.name,
        })
        .then((res) => {
          this.showEditView = false;
        });
    },
    cancel() {
      if (this.showNewMember === true) {
        this.showNewMember = false;
        this.newMemberFormState.email = "";
        this.newMemberFormState.name = "";
      }
      if (this.showEditView === true) {
        this.showEditView = false;
        this.editFormState.name = "";
      }
    },
    submitNewMember() {
      if (
        !this.validateEmail(this.newMemberFormState.email) ||
        this.newMemberFormState.name === ""
      ) {
        this.popUpError("Invalid email or name");
      } else {
        var activeUsers = this.currentOrg.users.filter(
          (user) => user.status > 1
        );
        if (activeUsers.length < this.currentOrg.licenses) {
          const existingUser = this.currentOrg.users.filter(
            (user) => user.email === this.newMemberFormState.email
          );
          if (existingUser.length > 0) {
            this.popUpError("This user has already been added to team.");
          } else {
            this.store
              .dispatch("user/ADD_NEW_MEMBER", {
                orgId: this.currentOrg.id,
                email: this.newMemberFormState.email.toLowerCase(),
                name: this.newMemberFormState.name,
              })
              .then(() => {
                this.showNewMember = false;
                var newForm = { name: "", email: "" };
                Object.assign(this.newMemberFormState, newForm);
              });
          }
        } else {
          this.popUpError(
            "You have reached the maximum number of licenses. Please upgrade or deactivate other members to add more members."
          );
        }
      }
    },
    changeStatus(user) {
      this.store.dispatch("user/UPDATE_STATUS", {
        id: user.id,
        status: user.status,
      });
    },
    removeMember(id) {
      this.store.dispatch("user/REMOVE_MEMBER", {
        orgId: this.currentOrg.id,
        uid: id,
      });
    },
    resetPassword(email) {
      this.store.dispatch("user/RESET_PASSWORD", {
        email: email,
      });
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    popUpError(error) {
      this.$error({
        title: "Oops! Please check",
        content: error,
      });
    },
  },
};
</script>
